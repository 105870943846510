/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { AppState } from '../../../context/stores/rootReducers';
import { useSelector } from 'react-redux';
import { BaseResponse } from '../../../context/base/BaseResponse';
import { rootApi } from '../../../context/api/rootApi';
import { HocSinhBaoCaoTongQuanResponse } from '../../../context/models/mega/HocSinhBaoCaoTongQuanResponse';
import BaoCaoHocSinhPieChart from '../baocao-theokhoi/BaoCaoHocSinhPieChart';
import BaoCaoHocSinhTongQuanChart from './BaoCaoHocSinhTongQuanChart';
import avt1 from '../../../assets/images/1.svg';
import avt2 from '../../../assets/images/2.svg';

const BaoCaoTongQuanBox = () => {
	const { dm_coso_id, dm_truong_id, nam_hoc } = useSelector((state: AppState) => state.common);

	const [dataReport, setDataReport] = useState<HocSinhBaoCaoTongQuanResponse[]>([]);

	const handleReloadData = useCallback(async () => {
		const res: BaseResponse = await rootApi.baoCao.SelectTongQuanAsync(dm_coso_id, dm_truong_id, nam_hoc);
		if (res.isSuccess && res.result) {
			const listCategory = res.result as HocSinhBaoCaoTongQuanResponse[];
			setDataReport(listCategory);
		} else {
			setDataReport([]);
		}
	}, [dm_coso_id, nam_hoc]);

	useEffect(() => {
		handleReloadData();
	}, [dm_coso_id, nam_hoc]);

	const reportGrouped = useMemo(() => {
		if (dm_truong_id <= 0) {
			let result: HocSinhBaoCaoTongQuanResponse = {
				hocsinh_total: 0,
				dm_coso_id: 0,
				id: 0,
				ten_truong: '',
				ten_truong_en: '',
				hocsinh_danghoc: 0,
				hocsinh_baoluu: 0,
				hocsinh_cholop: 0,
				hocsinh_nam: 0,
				hocsinh_nu: 0
			};
			const truongs = dataReport.filter((map_he) => map_he.dm_coso_id === dm_coso_id);
			if (truongs) {
				truongs.forEach((solieu) => {
					result = {
						...result,
						hocsinh_total: result.hocsinh_total + solieu.hocsinh_total,
						hocsinh_danghoc: result.hocsinh_danghoc + solieu.hocsinh_danghoc,
						hocsinh_cholop: result.hocsinh_cholop + solieu.hocsinh_cholop,
						hocsinh_baoluu: result.hocsinh_baoluu + solieu.hocsinh_baoluu,
						hocsinh_nam: result.hocsinh_nam + solieu.hocsinh_nam,
						hocsinh_nu: result.hocsinh_nu + solieu.hocsinh_nu
					};
				});
			}
			return result;
		}
		return dataReport?.find((x) => x.id === dm_truong_id);
	}, [dataReport, dm_coso_id, dm_truong_id]);

	return (
		<>
			<div className="col-xs-12 title-detail">
				<span>Tổng quan</span>
			</div>
			<div className="col-xs-12 box-content">
				{/* <!-- Tổng số học sinh box (tỉ lệ 1) --> */}
				<div className="box box-1">
					<div className="title">Tổng số học sinh</div>
					<div className="male" style={{fontStyle:'italic'}}>(Bao gồm học sinh đã xếp lớp và chưa được xếp lớp)</div>
					<div className="total">{reportGrouped?.hocsinh_total}</div>
					<div className="info">
						{/* <div>
							<span>Đang học: </span>
							<span> {reportGrouped?.hocsinh_danghoc}</span>
						</div> */}
						{/* <div>
							<span>Chờ lớp: </span>
							<span> {reportGrouped?.hocsinh_cholop}</span>
						</div>
						<div>
							<span>Bảo lưu: </span>
							<span> {reportGrouped?.hocsinh_baoluu}</span>
						</div> */}
						<div className="gender-info" style={{border: 0}}>
							<div>
								<span className="male"> <img src={avt2} /> Nam: </span>
								<span> {reportGrouped?.hocsinh_nam}</span>
							</div>
							<div>
								<span className="female"> <img src={avt1} /> Nữ: </span>
								<span> {reportGrouped?.hocsinh_nu}</span>
							</div>
						</div>
					</div>
				</div>
				{/* <!-- Biểu đồ box (tỉ lệ 2) --> */}
				<div className="box box-2 no-margin">
					{/* <div className="title">Sự biến động số lượng học sinh</div> */}
					<div className="chart-placeholder">
						<BaoCaoHocSinhTongQuanChart/>
					</div>
				</div>
			</div>
			{/* <!-- end danh muc dung chung --> */}
			{/* <div className='nk-block'>
				<div className='row g-gs'>
					<div className='col-sm-3 col-xxl-3'>
						<div className='card card-full bg-primary'>
							<div className='card-inner'>
								<div className='d-flex align-items-center justify-content-between mb-1'>
									<div className='fs-4 text-white text-opacity-75 mb-0'>Lớp học</div>
								</div>
								<h5 className='fs-1 text-white'>
									{reportGrouped?.lop_total} <small className='fs-6'>lớp</small>
								</h5>
							</div>
						</div>
					</div>
					<div className='col-sm-3 col-xxl-3'>
						<div className='card card-full bg-warning is-dark'>
							<div className='card-inner'>
								<div className='d-flex align-items-center justify-content-between mb-1'>
									<div className='fs-4 text-white text-opacity-75 mb-0'>Học sinh</div>
								</div>
								<h5 className='fs-1 text-white'>
									{reportGrouped?.hocsinh_total} <small className='fs-6'>học sinh</small>
								</h5>
							</div>
						</div>
					</div>
				</div>
			</div> */}
		</>
	);
};

export default BaoCaoTongQuanBox;
